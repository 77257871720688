import FeatureTypeConverter from "@/utils/FeatureTypeConverter";
import featureTypes, {FeatureTypes} from "@/constants/FeatureTypes";

export default class Organisation {
  public organisationId: string;
  public organisationName: string | undefined;
  public servicePartners: Map<string, Array<string>>;
  public organisationFeatures: Array<string>;
  public smartSisEnabled: boolean;
  public geoserverLayerCatalogApisAccessEnabled: boolean;

  constructor(
    organisationId: string,
    servicePartners: Map<string, Array<string>>,
    organisationFeatures: Array<string>,
    smartSisEnabled: boolean,
    geoserverLayerCatalogApisAccessEnabled: boolean,
    organisationName?: string
  ) {
    this.organisationId = organisationId;
    this.organisationName = organisationName;
    this.servicePartners = servicePartners;
    this.organisationFeatures = organisationFeatures;
    this.smartSisEnabled = smartSisEnabled;
    this.geoserverLayerCatalogApisAccessEnabled = geoserverLayerCatalogApisAccessEnabled;
  }

  public setFeatures(featuresAndRights: Array<any>): void {
    const selectedFeatures: Array<FeatureTypes> = featuresAndRights.map(f => featureTypes[f]).filter(f => f);
    const listOfSelectedServices = Array.from(
      new Set(selectedFeatures.map(f => FeatureTypeConverter.getServiceForFeatureName(f.name)).filter(s => s))
    );
    this.servicePartners.forEach((partners, service) => {
      if (!listOfSelectedServices.includes(service)) {
        this.servicePartners.delete(service);
      }
    });
    listOfSelectedServices.forEach(service => {
      if (service && !this.servicePartners.has(service)) {
        this.servicePartners.set(service, new Array<string>());
      }
    });
    this.organisationFeatures = featuresAndRights;
  }

  public getSharedFeatures(selectedPartner: string): Array<any> {
    return [...this.servicePartners.entries()].filter(({1: partner}) => partner.includes(selectedPartner))
      .map(([service]) => FeatureTypeConverter.getFeatureTypesForService(service))
      .flat();
  }

  public setSharedFeatures(partner: string, sharedFeatures: Array<any>) {
    const servicesAssociatedWithFeatures =
      sharedFeatures.map(sf => FeatureTypeConverter.getServiceForFeatureName(sf.name)).filter(service => service);
    for (const service of this.servicePartners) {
      if (this.servicePartners.get(service[0])?.includes(partner) && !servicesAssociatedWithFeatures.includes(service[0])) {
        let connectedPartners = this.servicePartners.get(service[0]);
        connectedPartners = connectedPartners?.filter(p => p !== partner);
        this.servicePartners.set(service[0], connectedPartners as string[]);
      } else if (!this.servicePartners.get(service[0])?.includes(partner) && servicesAssociatedWithFeatures.includes(service[0])) {
        const connectedPartners = this.servicePartners.get(service[0]);
        connectedPartners?.push(partner);
        this.servicePartners.set(service[0], connectedPartners as string[]);
      }
    }
  }

  public sharesFeaturesWithPartner(partnerName: string) {
    const entriesArray: Array<[string, string[]]> = [...this.servicePartners.entries()];
    return entriesArray.some(entry => entry[1].some(currentPartnerName => currentPartnerName === partnerName));
  }

  public notMatchesServicePartners(other: Organisation): boolean {
    return Array.from(this.servicePartners.keys()).some(service => !other.servicePartners.has(service) ||
      this.servicePartners.get(service)?.some(partner => !other.servicePartners.get(service)?.includes(partner)) ||
      other.servicePartners.get(service)?.some(partner => !this.servicePartners.get(service)?.includes(partner))
    );
  }

  public notMatchesOrganisationFeatures(other: Organisation): boolean {
    return Array.from(this.organisationFeatures).some(feat => !other.organisationFeatures.includes(feat)) ||
      Array.from(other.organisationFeatures).some(feat => !this.organisationFeatures.includes(feat));
  }

  public smartSisEnabledChanged(other: Organisation): boolean {
    return other.smartSisEnabled != this.smartSisEnabled;
  }

  public geoserverLayerCatalogApisAccessChanged(other: Organisation): boolean {
    return other.geoserverLayerCatalogApisAccessEnabled != this.geoserverLayerCatalogApisAccessEnabled;
  }

  public copy(): Organisation {
    const servicePartnersCopy = new Map<string, Array<string>>();
    Array.from(this.servicePartners.keys())
      .forEach(key => servicePartnersCopy.set(key, JSON.parse(JSON.stringify(this.servicePartners.get(key)))));
    const organisationFeaturesCopy = new Array<string>();
    this.organisationFeatures
      .forEach(feature => organisationFeaturesCopy.push(feature));
    return new Organisation(this.organisationId, servicePartnersCopy, organisationFeaturesCopy, this.smartSisEnabled,
      this.geoserverLayerCatalogApisAccessEnabled, this.organisationName);
  }

  public static empty(): Organisation {
    return new Organisation('', new Map<string, Array<string>>(), [], true, false, '');
  }

}