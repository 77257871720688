export interface FeatureRight {
  name: string;
  displayValue: string;
  belongsToFeature: string;
  prerequisiteRight: string | null; // This field stores the feature right which needs to be added for a feature in order to be able to select the given feature right
  inheritedRight: string | null; // This field stores the feature right which needs to be added/removed together with the given feature right
}

const featureRights: { [key: string]: FeatureRight } = {};

export default featureRights;

featureRights['feature_alerting'] = {
  name: 'feature_alerting',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_alerting',
  prerequisiteRight: null,
  inheritedRight: null,
};

featureRights['feature_uvr'] = {
  name: 'feature_uvr',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_uvr',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_ed269'] = {
  name: 'feature_ed269',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_ed269',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_convertToIoatFpl'] = {
  name: 'feature_convertToIoatFpl',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_convertToIoatFpl',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_met'] = {
  name: 'feature_met',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_met',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_sail'] = {
  name: 'feature_sail',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_sail',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_operationPlans_read'] = {
  name: 'feature_operationPlans_read',
  displayValue: 'Read',
  belongsToFeature: 'feature_operationPlans',
  prerequisiteRight: null,
  inheritedRight: 'feature_operationPlans',
}

featureRights['feature_operationPlans_write_low'] = {
  name: 'feature_operationPlans_write_low',
  displayValue: 'Create - Low Priority',
  belongsToFeature: 'feature_operationPlans',
  prerequisiteRight: 'feature_operationPlans_read',
  inheritedRight: 'feature_operationPlans_write',
}

featureRights['feature_operationPlans_write_medium'] = {
  name: 'feature_operationPlans_write_medium',
  displayValue: 'Create - Medium Priority',
  belongsToFeature: 'feature_operationPlans',
  prerequisiteRight: 'feature_operationPlans_read',
  inheritedRight: 'feature_operationPlans_write',
}

featureRights['feature_operationPlans_write_high'] = {
  name: 'feature_operationPlans_write_high',
  displayValue: 'Create - High Priority',
  belongsToFeature: 'feature_operationPlans',
  prerequisiteRight: 'feature_operationPlans_read',
  inheritedRight: 'feature_operationPlans_write',
}

featureRights['feature_telemetry'] = {
  name: 'feature_telemetry',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_telemetry',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_notam'] = {
  name: 'feature_notam',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_notam',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_networkCoverage'] = {
  name: 'feature_networkCoverage',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_networkCoverage',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_militaryOperationPlan'] = {
  name: 'feature_militaryOperationPlan',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_militaryOperationPlan',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_laraReservations'] = {
  name: 'feature_laraReservations',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_laraReservations',
  prerequisiteRight: null,
  inheritedRight: null,
}

featureRights['feature_icaoFlightPlans'] = {
  name: 'feature_icaoFlightPlans',
  displayValue: 'Enabled',
  belongsToFeature: 'feature_icaoFlightPlans',
  prerequisiteRight: null,
  inheritedRight: null,
}