import BaseApi from "@/api/BaseApi";
import Organisation from "@/model/Organisation";
import OrganisationWithUser from "@/model/OrganisationWithUser";
import ApiEndpoints from "@/constants/apiEndpoints";
import User from "@/model/User";

class OrganisationApi extends BaseApi {

  public async saveOrganisationWithUser(organisationWithUser: OrganisationWithUser): Promise<Response> {
    return await this.post(ApiEndpoints.ORGANISATION_WITH_USER, organisationWithUser);
  }

  public async getOrganisationWithUser(organisationId: string): Promise<OrganisationWithUser> {
    const apiResponse: Response = await this.get(ApiEndpoints.ORGANISATION_WITH_USER, [organisationId]);
    return Promise.resolve(OrganisationApi.constructOrganisationWithUserFromJson(await apiResponse.json()));
  }

  public async deleteOrganisation(organisationId: string): Promise<Response> {
    return await this.delete(ApiEndpoints.ORGANISATION, [organisationId]);
  }

  public async getOrganisationIds(): Promise<Array<string>> {
    const apiResponse: Response = await this.get(ApiEndpoints.ORGANISATION_IDS);
    return await apiResponse.json();
  }

  public async getOrganisationSecret(organisationId: string): Promise<string> {
    const apiResponse: Response = await this.get(ApiEndpoints.ORGANISATION_SECRET, [organisationId]);
    return await apiResponse.text();
  }

  public async resetOrganisationSecret(organisationId: string): Promise<string> {
    const apiResponse: Response =
      await this.post(ApiEndpoints.ORGANISATION_SECRET, undefined, [organisationId]);
    return await apiResponse.text();
  }

  private static constructOrganisationWithUserFromJson(json: any): OrganisationWithUser {
    const organisation: Organisation = new Organisation(
      json.organisation.organisationId,
      new Map<string, Array<string>>(Object.entries(json.organisation.servicePartners)),
      json.organisation.organisationFeatures,
      json.organisation.smartSisEnabled,
      json.organisation.geoserverLayerCatalogApisAccessEnabled,
      json.organisation.organisationName
    );

    const user: User = json.user == null ? User.empty() :
      new User(
        json.user.firstName,
        json.user.lastName,
        json.user.mail,
        json.user.organisationId,
        json.user.username,
        json.user.password
      );
    return new OrganisationWithUser(organisation, user);
  }
}

export default new OrganisationApi();
