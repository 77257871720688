export interface FeatureTypes {
  name: string;
  displayValue: string;
  belongsToService: string | null;
}

const featureTypes: { [key: string]: FeatureTypes } = {};

featureTypes['feature_alerting'] = {
  name: 'feature_alerting',
  displayValue: 'Alerting',
  belongsToService: 'ALERTING',
};

featureTypes['feature_uvr'] = {
  name: 'feature_uvr',
  displayValue: 'Uvr',
  belongsToService: 'RAS',
};

featureTypes['feature_ed269'] = {
  name: 'feature_ed269',
  displayValue: 'ED-269',
  belongsToService: 'RAS',
};

featureTypes['feature_convertToIoatFpl'] = {
  name: 'feature_convertToIoatFpl',
  displayValue: 'Op to iOAT Fpl Converter',
  belongsToService: 'FPL_CONVERTER',
};

featureTypes['feature_met'] = {
  name: 'feature_met',
  displayValue: 'Met',
  belongsToService: null,
};

featureTypes['feature_sail'] = {
  name: 'feature_sail',
  displayValue: 'Sail Details',
  belongsToService: 'UTM',
};

featureTypes['feature_operationPlans'] = {
  name: 'feature_operationPlans',
  displayValue: 'Operation Plans',
  belongsToService: 'UTM',
};

featureTypes['feature_telemetry'] = {
  name: 'feature_telemetry',
  displayValue: 'Telemetry',
  belongsToService: 'TELEMETRY',
};

featureTypes['feature_notam'] = {
  name: 'feature_notam',
  displayValue: 'Notam',
  belongsToService: null,
};

featureTypes['feature_networkCoverage'] = {
  name: 'feature_networkCoverage',
  displayValue: 'Network Coverage',
  belongsToService: 'UTM',
};

featureTypes['feature_militaryOperationPlan'] = {
  name: 'feature_militaryOperationPlan',
  displayValue: 'Military Operation Plans',
  belongsToService: 'UTM',
};

featureTypes['feature_laraReservations'] = {
  name: 'feature_laraReservations',
  displayValue: 'Lara Airspaces',
  belongsToService: 'RAS',
};

featureTypes['feature_icaoFlightPlans'] = {
  name: 'feature_icaoFlightPlans',
  displayValue: 'ICAO Flight Plans',
  belongsToService: 'RAS',
};

export default featureTypes
